import * as React from "react"

const EstimateView = ({ record }) => {
	const {
		EstimateID,
		FirstName,
		LastName,
		Email,
		Sessions,
		ParticipantType,
		PrimaryResearcher,
		SecondaryResearcher,
		Objective1,
		ObjectiveNotes,
	} = record

	const amountFields = [
		'PrimaryResearcherCost',
		'SecondaryResearcherCost',
		'PMSubTotal',
		'DesignSubTotal',
		'RecruitSubTotal',
		'SessionSubTotal',
		'FieldworkSubTotal',
		'AnalysisSubtotal',
		'ReportingSubTotal',
		'OutcomesSubTotal',
		'TotalEstimate',
		'GST',
	]

	amountFields.forEach(fieldName => {
		record[fieldName] = record[fieldName] ?? 0
		record[fieldName] = record[fieldName].toLocaleString('en-GB', {
	    minimumFractionDigits: 2, 
	    maximumFractionDigits: 2
	  })
	})

	let {
		PrimaryResearcherCost,
		SecondaryResearcherCost,
		PMSubTotal,
		DesignSubTotal,
		RecruitSubTotal,
		SessionSubTotal,
		FieldworkSubTotal,
		AnalysisSubtotal,
		ReportingSubTotal,
		OutcomesSubTotal,
		TotalEstimate,
		GST,
		CTA,
	} = record

	return (
		<div className="overflow-hidden bg-white shadow sm:rounded-lg">
	      <div className="px-4 py-5 sm:px-6">
	        <h3 className="text-lg font-medium leading-6 text-gray-900">{EstimateID}</h3>
	      </div>
	      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
	        <dl className="sm:divide-y sm:divide-gray-200">
	          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
	            <dt className="text-sm font-medium text-gray-500">Name</dt>
	            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{FirstName} {LastName}</dd>
	          </div>
	          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
	            <dt className="text-sm font-medium text-gray-500">Email address</dt>
	            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{Email}</dd>
	          </div>
	          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
	            <dt className="text-sm font-medium text-gray-500">Sessions</dt>
	            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{Sessions}</dd>
	          </div>
	          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
	            <dt className="text-sm font-medium text-gray-500">Participant Type</dt>
	            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{ParticipantType}</dd>
	          </div>
	          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
	            <dt className="text-sm font-medium text-gray-500">Primary Researcher</dt>
	            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
	            	<span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
          				{PrimaryResearcher}
        				</span>
        			</dd>
	          </div>
	          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
	            <dt className="text-sm font-medium text-gray-500">Secondary Researcher</dt>
	            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
	            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
          			{SecondaryResearcher}
        			</span>
	            </dd>
	          </div>
	          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
	            <dt className="text-sm font-medium text-gray-500">Objective</dt>
	            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
	              {Objective1}
	            </dd>
	          </div>
	          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
	            <dt className="text-sm font-medium text-gray-500">Objective Notes</dt>
	            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
	              {ObjectiveNotes}
	            </dd>
	          </div>
	          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
	            <dt className="text-sm font-medium text-gray-500">Primary Researcher Cost</dt>
	            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">${PrimaryResearcherCost}</dd>
	          </div>
	          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
	            <dt className="text-sm font-medium text-gray-500">Secondary Researcher Cost</dt>
	            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">${SecondaryResearcherCost}</dd>
	          </div>
	          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
	            <dt className="text-sm font-medium text-gray-500">Project Manager</dt>
	            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">${PMSubTotal}</dd>
	          </div>
	          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
	            <dt className="text-sm font-medium text-gray-500">Design</dt>
	            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">${DesignSubTotal}</dd>
	          </div>
	          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
	            <dt className="text-sm font-medium text-gray-500">Recruitment</dt>
	            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">${RecruitSubTotal}</dd>
	          </div>
	          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
	            <dt className="text-sm font-medium text-gray-500">Session</dt>
	            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">${SessionSubTotal}</dd>
	          </div>
	          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
	            <dt className="text-sm font-medium text-gray-500">Field work</dt>
	            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">${FieldworkSubTotal}</dd>
	          </div>
	          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
	            <dt className="text-sm font-medium text-gray-500">Analysis</dt>
	            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">${AnalysisSubtotal}</dd>
	          </div>
	          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
	            <dt className="text-sm font-medium text-gray-500">Reporting</dt>
	            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">${ReportingSubTotal}</dd>
	          </div>
	          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
	            <dt className="text-sm font-medium text-gray-500">Outcomes</dt>
	            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">${OutcomesSubTotal}</dd>
	          </div>
	          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
	            <dt className="text-sm font-medium text-gray-500">Total</dt>
	            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">${TotalEstimate}</dd>
	          </div>
	          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
	            <dt className="text-sm font-medium text-gray-500">GST</dt>
	            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">${GST}</dd>
	          </div>
	          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
	            <dt className="text-sm font-medium text-gray-500">CTA</dt>
	            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{CTA}</dd>
	          </div>
	        </dl>
	      </div>
	    </div>
	)
}

export default EstimateView