import * as React from "react"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import { useQuery } from "@tanstack/react-query"
import EstimateView from "../../components/views/estimate"
import { 
	getResearchEstimate,
	handleAirtableResponse 
} from "../../util/api/path"
import Layout from "../../components/layouts/layout"

const EstimatePage = (props) => {
	let record = []
	let title = `Estimate`
	const estimateId = props.params.id
	const response = useQuery(['estimate', estimateId], () => getResearchEstimate({ estimateId }))
	if (response.isLoading === false) {
		record = handleAirtableResponse(response)
		title = record.EstimateID
  }
	return (
		<Layout title={title}>
			{response.isLoading && <div>Loading...</div>}
			{record ? <EstimateView record={record} /> : null}
		</Layout>
	)
}

export default withAuthenticationRequired(EstimatePage)